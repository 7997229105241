<template>
    <component :is="(component == 'card')?'VCard' : (isDesktop?'VDialog':'VBottomSheet')"
               v-model="informDialog"
               :elevation="24"
               :persistent="!success_result"
               class="mx-auto inform-dialog"
               :class="(component == 'card' && isDesktop)?'p-4 my-6':''"
               :max-width="isDesktop?(component == 'card'?'600px':'480px'):'100%'"
               :transition="isDesktop?'dialog-transition':'dialog-bottom-transition'">
        <template v-slot:activator="{on,attrs}">
            <slot name="activator"
                  :on="on"
                  :attr="attrs"></slot>
        </template>
        <!-- dialog แจ้งสำเร็จ (เฉพาะที่มาจาก myorders : directInform -->
        <v-card v-if="success_result && directInform"
                :flat="component == 'card'">
            <v-card-title flat
                          class="pt-6"
                          color="white">
                <v-toolbar-title>
                    {{ $t('แจ้งชำระเงินเรียบร้อยแล้ว') }}
                </v-toolbar-title>
            </v-card-title>
            <v-card-text>
                {{ $t('ร้านค้าได้รับข้อมูลการแจ้งชำระเงินของคุณเรียบร้อยแล้ว และกำลังตรวจสอบยอดเงินที่แจ้งเพื่อยืนยันการสั่งซื้อของคุณ') }}
            </v-card-text>
            <v-card-actions class="pb-5 flex_wrap"
                            style="gap: 10px;">
                <v-btn color="primary"
                       large
                       rounded
                       width="150"
                       @click="closeInformDialogWithSuccess()">
                    <v-icon color="white"
                            class="mr-2">mdi-check
                    </v-icon>
                    เสร็จสิ้น
                </v-btn>
                <v-btn color="primary"
                       text
                       @click="success_result = false;">{{ $t('แจ้งชำระเงินใหม่อีกครั้ง') }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <!-- dialog แจ้งโอนเงิน -->
        <v-card v-else
                class="informDialog"
                :flat="component == 'card'"
                :loading="isUploading || isSaving">
            <debug-indicator file-name="src/components/payment/LnwPaymentComponent.vue"
                             detail="v-toolbar"></debug-indicator>
            <v-toolbar v-if="component != 'card' || isLogin"
                       flat
                       color="white"
                       :class="isDesktop?'pt-1':'py-2 mb-2'">
                <v-toolbar-title>{{ $t('แจ้งโอนเงิน (ส่งสลิป)') }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-if="component != 'card'"
                       icon
                       :disabled="isSaving"
                       @click="informDialog = false">
                    <v-icon color="primary">mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-form ref="form"
                    v-model="valid"
                    lazy-validation
                    class="px-4 pt-2"
                    :class="!isDesktop?'pt-0':''">
                <v-alert v-show="!isLogin && !orderData.order_id"
                         text
                         type="info"
                         class="my-5 rounded-lg"
                         color="primary">
                    <a class="just_flex"
                       :href="'/order/' + shopData.web_name + '?inform=true'"> {{ $t('เข้าสู่ระบบเพื่อแจ้งโอนอย่างรวดเร็ว') }}
                        <v-icon class="mr-2"
                                color="primary">mdi-login
                        </v-icon>
                    </a>
                </v-alert>
                <div v-if="false"
                     class="grey--text mb-6">
                    {{ $t('หากคุณได้โอนเงินแล้ว กรุณาแจ้งชำระเงินด้านล่างนี้') }}
                </div>
                <v-subheader v-if="!isLogin && !orderData.order_id"
                             class="pl-0 primary--text">{{ $t('ข้อมูลโอนเงิน') }}
                </v-subheader>
                <v-combobox v-if="directInform"
                            class="order-chips"
                            :return-object="false"
                            ref="informPaymentBank"
                            v-model="informPayment.order_ids"
                            @change="changePaymentMethodByOrders"
                            :label="$t('เลขที่บิลรายการสั่งซื้อ')"
                            :items="ordersItemInComboBox"
                            item-text="order_id"
                            :menu-props="{closeOnContentClick: true}"
                            item-value="order_id"
                            outlined
                            :rules="rules_orders"
                            bottom
                            hide-no-data
                            :search-input.sync="search"
                            multiple
                            chips>
                    <template v-slot:item="{ item }">
                        <v-list-item-action>
                            <v-img class="rounded-lg"
                                   :src="item.items[0].thumbnail"
                                   :aspect-ratio="1"
                                   height="40"
                                   width="40"></v-img>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title style="line-height: 1.5"
                                               v-text="$t('บิลที่') +' '+ item.order_id"></v-list-item-title>
                            <v-list-item-subtitle>
                                <v-tooltip v-if="item.is_lnwpay"
                                           top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img v-bind="attrs"
                                             v-on="on"
                                             style="width: 14px; vertical-align: text-top"
                                             :src="require('@/assets/lnwpay_shield_square.png')"
                                             alt="LnwPay">
                                    </template>
                                    <span style="text-transform: capitalize">{{ $t('ชำระผ่าน LnwPay') }}</span>
                                </v-tooltip>
                                <span class="minor"> ฿{{ number_format(item.net_price, 0) }}</span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action-text>
                            <span :class="orderMeta[item.status].text_color">{{ orderMeta[item.status].text }}</span>
                        </v-list-item-action-text>
                    </template>
                </v-combobox>
                <debug-indicator file-name="src/components/payment/LnwPaymentComponent.vue"
                                 detail="ถ้าจะแก้ข้อความใน dropdown ให้แก้ที่ computed->bankPaymentMethodBanks"></debug-indicator>
                <v-alert v-if="isSelectedOrdersIsLnwPay == 'unIdentifiable'"
                         dense
                         text
                         type="info"
                         class="mb-5">
                    <span class="text-sm-body-2">{{ $t('กรุณาตรวจสอบเลขที่บิลสั่งซื้ออีกครั้ง หากคุณมั่นใจว่าถูกต้องแล้ว กรุณาดำเนินการต่อ') }}</span>
                </v-alert>
                <v-alert text
                         color="primary"
                         class="mb-5">
                    <div class="just_flex text-sm-body-2">
                        <span><v-icon color="primary"
                                      small
                                      class="mr-2">mdi-information-outline</v-icon> {{ $t('ยอดเงินที่ต้องชำระ') }}</span>
                        <span><b v-if="this.totalNetPrice">฿{{ number_format(this.totalNetPrice, 2) }}</b><b v-else>฿-</b></span>
                    </div>
                </v-alert>
                <v-select ref="informPaymentBank"
                          v-model="informPayment.bank"
                          :label="$t('คุณโอนมาที่บัญชีธนาคาร')"
                          :items="bankPaymentMethodBanks"
                          item-text="dropdown_select_text"
                          item-value="dropdown_value"
                          outlined
                          :menu-props="{closeOnContentClick: true, maxHeight: 330}"
                          :rules="rules_required"
                          required>
                    <template v-slot:selection="{ item, index }">
                        <v-list-item style="margin: 0; padding: 0 10px;">
                            <v-list-item-action style="min-width: 40px;">
                                <img class="m-0"
                                     style="border-radius: 5px"
                                     width="40"
                                     :src="`https://www.lnwpay.com/images/banks/${item.detail.account_key}.jpg`">
                            </v-list-item-action>
                            <v-list-item-content style="padding: 0;">
                                <div>{{ item.name }}</div>
                                <div v-html="item.dropdown_text"
                                     style="max-width: 80%;"
                                     class="minor text-overflow text-no-wrap"></div>
                            </v-list-item-content>
                            <v-list-item-action style="margin-left: 0;">
                                <v-fade-transition leave-absolute>
                                    <v-btn @click.stop="snackbar = true"
                                           style="min-width: 40px"
                                           small
                                           text>
                                        <div class="primary--text"
                                             v-clipboard:copy="item.detail.account_number">
                                            <v-icon color="primary"
                                                    small>mdi-content-copy
                                            </v-icon>
                                        </div>
                                    </v-btn>
                                </v-fade-transition>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                    <template v-slot:item="{ index, item }">
                        <v-list-item :disabled="isDisableThisPaymentMethod(item)"
                                     @click="chooseThisPaymentMethod(item)">
                            <v-list-item-action :class="(isDisableThisPaymentMethod(item))?'disabled':''">
                                <img class="m-0"
                                     style="border-radius: 5px"
                                     width="40"
                                     :src="`https://www.lnwpay.com/images/banks/${item.detail.account_key}.jpg`">
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                <v-list-item-subtitle v-html="item.dropdown_text"></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-select>
                <v-text-field ref="informPaymentAmount"
                              v-model="informPayment.amount"
                              @change="changeOrderPrice"
                              :label="$t('ยอดเงินที่โอน')"
                              clearable
                              inputmode="decimal"
                              :suffix="diffOrderText"
                              v-disabled-icon-focus
                              outlined
                              prepend-inner-icon="mdi-currency-thb"
                              type="number"
                              :rules="rules_amount"
                              required></v-text-field>
                <div class="just_flex_start"
                     style="gap: 20px;">
                    <template v-if="true">
                        <div style="width: 45% !important;"
                             class="d-inline-block">
                            <label>
                                <v-text-field ref="informPaymentTime"
                                              class="timeInputText"
                                              v-model="informPayment.time"
                                              :label="$t('เวลาที่โอน')"
                                              prepend-inner-icon="mdi-clock-time-ten-outline"
                                              outlined
                                              type="time"
                                              pattern="[0-9]{2}:[0-9]{2}"
                                              :rules="rules_required"></v-text-field>
                            </label>
                        </div>
                    </template>
                    <template v-else>
                        <v-dialog ref="menuTimeDialog"
                                  v-model="timeDialog"
                                  :close-on-content-click="false"
                                  :return-value.sync="informPayment.time"
                                  transition="scale-transition"
                                  persistent
                                  width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <div style="width: 45% !important;"
                                     class="d-inline-block">
                                    <v-text-field ref="informPaymentTime"
                                                  v-model="informPayment.time"
                                                  :label="$t('เวลา')"
                                                  prepend-inner-icon="mdi-clock-time-ten-outline"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  readonly
                                                  outlined
                                                  :rules="rules_required"></v-text-field>
                                </div>
                            </template>
                            <v-time-picker v-if="timeDialog"
                                           format="24hr"
                                           :locale="$i18n.locale"
                                           v-model="informPayment.time"
                                           @click:minute="$refs.menuTimeDialog.save(informPayment.time)"></v-time-picker>
                        </v-dialog>
                    </template>
                    <v-dialog v-model="dateDialog"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <label class="d-inline-block w-50">
                                <v-text-field ref="informPaymentDate"
                                              :value="informPayment.date?clock.show_date(informPayment.date)+((clock.now_date() == informPayment.date)?' · ' + $t('วันนี้'):''):''"
                                              :label="$t('วันที่โอน')"
                                              prepend-inner-icon="mdi-calendar"
                                              outlined
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                              hide-details
                                              :rules="rules_required"></v-text-field>
                            </label>
                        </template>
                        <v-date-picker v-model="informPayment.date"
                                       no-title
                                       :locale="$i18n.locale"
                                       @input="dateDialog = false"></v-date-picker>
                    </v-dialog>
                </div>
                <v-spacer></v-spacer>
                <template v-if="informPayment.files.length === 0">
                    <v-file-input :label="$t('ภาพสลิปโอนเงิน')"
                                  prepend-icon=""
                                  prepend-inner-icon="mdi-camera"
                                  outlined
                                  :rules="rules_file"
                                  @change="slipPicked"
                                  v-model="file"></v-file-input>
                </template>
                <div v-else-if="informPayment.files.length > 0"
                     class="mb-5">
                    <div class="just_flex"
                         v-for="img in informPayment.files"
                         :key="img">
                        <template v-if="img.indexOf('.pdf') > 0">
                            {{ img }}
                            <v-btn color="red"
                                   text
                                   @click="removeSlipFile(img)">
                                {{ $t('ลบไฟล์') }}
                            </v-btn>
                        </template>
                        <template v-else>
                            <v-img :src="img"
                                   max-width="100"
                                   min-height="50"
                                   class="mr-2 grey lighten-2">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0"
                                           align="center"
                                           justify="center">
                                        <v-progress-circular indeterminate
                                                             color="grey lighten-5"></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                            <v-btn color="red"
                                   text
                                   @click="removeSlipFile(img)">
                                {{ $t('ลบภาพ') }}
                            </v-btn>
                        </template>
                    </div>
                </div>
                <template v-if="!isLogin && !orderData.order_id">
                    <v-subheader class="pl-0 primary--text">{{ $t('ผู้แจ้งโอน') }}</v-subheader>
                    <v-text-field v-model="informPayment.name"
                                  :rules="rules_required"
                                  clearable
                                  v-disabled-icon-focus
                                  outlined
                                  validate-on-blur>
                        <template v-slot:label>
                            {{ $t('ชื่อ') }}
                        </template>
                        <template v-slot:prepend-inner>
                            <v-icon class="mr-2">mdi-account-circle
                            </v-icon>
                        </template>
                    </v-text-field>
                    <v-text-field v-model="informPayment.mobile"
                                  :rules="mobileRules"
                                  clearable
                                  v-disabled-icon-focus
                                  maxlength=10
                                  outlined
                                  autocomplete="tel"
                                  pattern="\d*"
                                  validate-on-blur
                                  @change="onlyNumberInput"
                                  @keypress="onlyNumberPress">
                        <template v-slot:label>
                            {{ $t('เบอร์มือถือ') }}
                        </template>
                        <template v-slot:prepend-inner>
                            <v-icon class="mr-2">mdi-cellphone
                            </v-icon>
                        </template>
                        <template v-slot:append>
                            <span class="text-body-2 disabled">{{ $t('เช่น') }} 0912345678</span>
                        </template>
                    </v-text-field>
                    <v-text-field v-model.trim="informPayment.email"
                                  :rules="emailRules"
                                  clearable
                                  autocomplete="email"
                                  v-disabled-icon-focus
                                  outlined
                                  validate-on-blur>
                        <template v-slot:label>
                            {{ $t('อีเมล') }}
                        </template>
                        <template v-slot:prepend-inner>
                            <v-icon class="mx-2">mdi-email-outline
                            </v-icon>
                        </template>
                        <template v-slot:append>
                            <span class="text-body-2 disabled">{{ $t('เช่น') }} example@gmail.com</span>
                        </template>
                    </v-text-field>
                    <v-textarea outlined
                                clearable
                                :label="$t('รายละเอียดเพิ่มเติม')"
                                rows="1"
                                v-model="informPayment.detail"></v-textarea>
                </template>
                <v-row style="position: sticky; bottom: 0;"
                       class="pt-2 pb-4 white">
                    <v-btn class="mr-4"
                           x-large
                           width="100%"
                           color="primary"
                           :loading="isUploading || isSaving"
                           :disabled="isUploading || isSaving"
                           @click="submitInformPayment">
                        {{ $t('ยืนยันแจ้งโอน') }}
                    </v-btn>
                </v-row>
            </v-form>
        </v-card>
        <v-snackbar v-model="snackbar">
            {{ $t('คัดลอกเรียบร้อย') }}
            <template v-slot:action="{ attrs }">
                <v-btn color="primary"
                       text
                       v-bind="attrs"
                       @click="snackbar = false">
                    {{ $t('ปิด') }}
                </v-btn>
            </template>
        </v-snackbar>
    </component>
</template>
<script>
import {VCard, VDialog, VBottomSheet} from 'vuetify/lib';
import DebugIndicator from "@/components/DebugTools/DebugIndicator.vue";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import Axios from "@/libraries/Axios.js";
import MixinsDisabledIconFocus from "@/mixins/MixinsDisabledIconFocus.vue";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import Helper from "@/libraries/Helper";
import {clock} from "@/libraries/DateTime";
import {SET_INFORM_DIALOG} from "@/store/modules/order/mutation-types";
import {isLocalhost} from "@/libraries/Utilities";
import MixinsOrderMeta from "@/mixins/MixinsOrderMeta";

const config_lnwfile = require('@/config/lnwfile.json');

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const mobileRegexTH = /^(\+\d{1,3}[- .]?\d{9}|0[0-9]{9})$/;
const mobileRegexEN = /^(\+\d{1,3}[- ]?)?\d{10}$/;

export default {
    name: "InformPaymentDialog",
    props: ['directInform', 'ordersItem', 'selectedOrder', 'component', 'guest'], // from myOrders
    components: {
        DebugIndicator,
        VCard,
        VDialog,
        VBottomSheet
    },
    mixins: [
        MixinsDisabledIconFocus,
        MixinsHelper,
        MixinsOrderMeta
    ],
    data(){
        return {
            isSaving: false,
            valid: true,
            snackbar: false,
            file: null,
            dateDialog: false,
            timeDialog: false,
            diffOrderPrice: null,
            informPayment: {
                files: [],
                date: clock.now_date(),
                time: null,
                amount: null,
                bank: null,
                order_ids: [],
                name: null,
                email: null,
                mobile: null,
                detail: null
            },
            isSelectedOrdersIsLnwPay: false,
            search: null,
            isUploading: false,
            success_result: false,
            mobileRules: [
                // v => !!v || this.$t('กรุณากรอกเบอร์มือถือด้วยนะคะ'),
                v => (!v
                    || this.validateMobileNumber(v))
                    || this.$t('กรุณากรอกเบอร์มือถือให้ถูกต้องนะคะ'),
            ],
            emailRules: [
                // v => !!v || this.$t('กรุณากรอก E-mail ด้วยนะคะ'),
                v => (!v || emailRegex.test(v)) || this.$t('กรุณากรอก E-mail ให้ถูกต้องนะคะ'),
            ],
            mixEmailMobileRules: [
                v => !!v || this.$t('กรุณากรอกข้อมูลให้ถูกต้องนะคะ'),
                v => (!v
                    || this.validateMobileNumber(v)
                    || emailRegex.test(v))
                    || this.$t('กรุณากรอกข้อมูลให้ถูกต้องนะคะ'),
            ],
            passwordRules: [
                v => !!v || this.$t('กรุณากรอกรหัสผ่านด้วยนะคะ'),
            ],
        };
    },
    computed: {
        ...mapGetters({
            isLogin: "user/isLogin",
            orderData: 'order/order',
            getShopFileNode: 'order/getShopFileNode',
            isMobile: 'device/isMobile',
            bankPaymentMethod: 'order/bankPaymentMethod',
            bankPaymentMethodBanks: 'order/bankPaymentMethodDropdownOptions',
            isShippingIsLastStep: 'order/isShippingIsLastStep',
            isWaitReceiverInfo: 'order/isWaitReceiverInfo',
            isWaitingShippingPrice: 'order/isWaitingShippingPrice',
            isRequiredInformPaymentFile: 'order/isRequiredInformPaymentFile',
            isProtectedByLnwPay: 'order/isProtectedByLnwPay',
        }),
        ...mapState({
            shopData: state => state.order.shopData,
        }),
        isLocalhost(){
            return isLocalhost();
        },
        ordersItemInComboBox(){
            return this.ordersItem;
        },
        totalNetPrice(){
            if(this.directInform && this.ordersItem){
                return this.ordersItem
                    .filter(order => this.informPayment.order_ids.includes(order.order_id))
                    .reduce((sum, order) => sum + order.net_price, 0);
            }
            return this.orderData?.price?.pay;
        },
        diffOrderText(){
            if(this.diffOrderPrice === null){
                return '';
            }
            if(this.diffOrderPrice > 0){
                return this.$t('ยอดโอนเกิน') + ' ฿' + this.number_format(this.diffOrderPrice, 2);
            }else if(this.diffOrderPrice < 0){
                return this.$t('ยอดโอนขาด') + ' ฿' + this.number_format(this.diffOrderPrice * -1, 2);
            }else{
                return this.$t('ยอดตรงพอดี');
            }
        },
        informDialog: {
            get(){
                return this.$store.state.order.informDialog;
            },
            set(value){
                this.$store.commit('order/' + SET_INFORM_DIALOG, value);
            },
        },
        rules_required(){
            return [
                v => !!v || this.$t('จำเป็นต้องระบุ'),
            ];
        },
        rules_file(){
            const rules = []
            if((this.isRequiredInformPaymentFile || (this.directInform && this.shopData.is_required_informpayment_file)) || this.isProtectedByLnwPay || this.isSelectedOrdersIsLnwPay === true){
                const rule = v => !!v || this.$t('จำเป็นต้องแนบสลิป');
                rules.push(rule);
            }
            return rules
        },
        rules_amount(){
            return this.rules_required.concat([v => !!(v && v.match(/^\d+(\.\d{1,2})?$/)) || 'รูปแบบไม่ถูกต้อง']);
        },
        rules_orders(){
            const rules = []
            if(this.directInform && this.shopData.is_inform_fix_order){
                const rule = v => v.length || this.$t('จำเป็นต้องระบุ');
                rules.push(rule);
            }
            return rules
        },
        // paymentDialog: {
        //     get() {
        //         return this.$store.state.order.form.isShowPaymentDialog;
        //     },
        //     set(newValue) {
        //         this.mutationSetForm({
        //             key: 'isShowPaymentDialog',
        //             value: newValue,
        //         })
        //     },
        // },
        clock(){
            return clock;
        },
    },
    methods: {
        ...mapActions({
            saveInformPayment: 'order/saveInformPayment',
            shrinkStep: 'order/shrinkStep',
            expandStep: 'order/expandStep'
        }),
        ...mapMutations({
            mutationSetForm: 'order/setForm',
        }),
        validateMobileNumber(number){
            if(this.$i18n.locale == 'th'){
                return mobileRegexTH.test(number);
            }else{
                return mobileRegexEN.test(number);
            }
        },
        onlyNumberPress(evt){
            if(evt){
                const charCode = (evt.which) ? evt.which : evt.keyCode;
                if((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46){
                    evt.preventDefault();
                }else{
                    return true;
                }
                this.onlyNumberInput();
            }
        },
        onlyNumberInput(){
            if(!this.informPayment.mobile){
                return
            }
            const filtered = this.informPayment.mobile.replace(/[^0-9]/g, '');
            if(filtered !== this.informPayment.mobile)
                this.informPayment.mobile = filtered;
        },
        number_format(num, decimal){
            return Helper.number_format(num, decimal);
        },
        slipPicked(file){

            if(!file){
                return false;
            }

            const fileType = file.type;
            // Check if the file type is either an image or a PDF
            if(!(/^image\//.test(fileType) || fileType === 'application/pdf')){
                return false;
            }


            this.isUploading = true;
            const formData = new FormData();
            formData.append('path', 'inform_payment');
            formData.append('web_id', this.$store.state.order.shopData?.web_id);
            formData.append('ajaxxxx', true);
            formData.append('ajaxxxx_dataType', 'json');
            formData.append('uploadfile', file);

            Axios.post(`/api/file/${this.getShopFileNode}/${config_lnwfile.upload_uri}`, formData, {
                headers: {
                    'Content-Type': 'multiple/form-data',
                },
            })
                .then((r) => {
                    if(r.data.status === 'success'){
                        if(this.informPayment.files.indexOf(r.data.url) < 0)
                            this.informPayment.files.push(r.data.url);
                    }
                    this.file = null;
                    this.isUploading = false;
                });
        },
        removeSlipFile(file){
            this.informPayment.files = this.informPayment.files.filter(i => i !== file);
        },
        async submitInformPayment(){
            if(!this.isInformPaymentFormDataReady())
                return false;

            let account_id = null;
            let is_lnwpay = null;

            if(Array.isArray(this.bankPaymentMethod.providers)){
                for(const provider of this.bankPaymentMethod.providers) {
                    if(provider.key === this.informPayment.bank){
                        account_id = provider.detail.account_id;
                        is_lnwpay = provider.is_lnwpay
                    }
                }
            }

            let payload = {
                provider: this.informPayment.bank,
                amount: this.informPayment.amount,
                payment_date: this.informPayment.date,
                payment_time: this.informPayment.time,
                file: this.informPayment.files.length ? this.informPayment.files[0] : false,
                account_id, // for LnwPay bank_transfer inform payment.
                is_lnwpay,
                name: this.informPayment.name,
                email: this.informPayment.email,
                mobile: this.informPayment.mobile,
                detail: this.informPayment.detail,
                directInform: this.directInform,
            };

            if(this.informPayment.order_ids.length){
                // console.log(this.informPayment.order_ids.length);
                payload['order_ids'] = this.informPayment.order_ids;
            }
            // console.log(payload);

            this.isSaving = true;
            let that = this;
            this.saveInformPayment(payload)
                .then((r) => {
                    if(!r.error){
                        that.informPayment.bank = null;
                        that.informPayment.date = null;
                        that.informPayment.time = null;
                        that.informPayment.amount = null;
                        that.informPayment.files = [];
                        that.informPayment.order_ids = [];
                        that.informPayment.name = null;
                        that.informPayment.email = null;
                        that.informPayment.mobile = null;
                        that.informPayment.detail = null;
                        that.isSaving = false;
                        if(this.directInform === true){
                            that.success_result = true;
                            that.$emit('success-inform-action');
                        }else{
                            setTimeout(() => {
                                that.shrinkStep({stepName: 'paymentMethod'}); // ยุบ step peymentMethod
                                if(that.isShippingIsLastStep){
                                    that.expandStep({stepName: 'shippingMethod'});
                                }else{
                                    that.shrinkStep({stepName: 'shippingMethod'});
                                }
                                that.scrollTo('#step1');
                            }, 1000)
                        }
                    }
                    that.isSaving = false;
                }).catch(function (){
                that.isSaving = false;
            });
        },
        isInformPaymentFormDataReady(){
            if(this.$refs.form && !this.$refs.form.validate())
                return false;

            return true;
        },
        isDisableThisPaymentMethod(item){
            if(this.isSelectedOrdersIsLnwPay == 'unIdentifiable'){
                return false; // ถ้าระบุไม่ได้ให้ผ่านไปก่อน
            }
            return item.is_lnwpay != this.isSelectedOrdersIsLnwPay;
        },
        chooseThisPaymentMethod(item){
            if(!this.isDisableThisPaymentMethod(item)){
                this.informPayment.bank = item.dropdown_value
            }
        },
        changeOrderPrice(){
            if(!this.informPayment.amount){
                this.diffOrderPrice = null;
            }
            this.diffOrderPrice = parseFloat(this.informPayment.amount) - parseFloat(this.totalNetPrice);
        },
        changePaymentMethodByOrders(){
            this.informPayment.bank = null;

            let isSomeLnwPay = false;
            let unIdentifiable = false;
            this.isSelectedOrdersIsLnwPay = false;

            if(!this.ordersItem){
                if(this.orderData.lnwpay_flag){
                    isSomeLnwPay = true;
                }else{
                    unIdentifiable = true;
                }
            }else{
                this.informPayment.order_ids.forEach(orderId => {
                    const foundOrder = this.ordersItem.find(order => order.order_id === orderId);
                    if(foundOrder){
                        if(foundOrder.is_lnwpay){
                            isSomeLnwPay = true;
                        }
                    }else{
                        unIdentifiable = true;
                    }
                });
            }

            if(isSomeLnwPay){
                this.isSelectedOrdersIsLnwPay = true;
            }
            if(unIdentifiable){
                this.isSelectedOrdersIsLnwPay = 'unIdentifiable';
            }
        },
        closeInformDialogWithSuccess(){
            this.informDialog = false;
            let that = this;
            setTimeout(() => {
                that.success_result = false;
            }, 500);
        }
    },
    mounted(){
        // console.log(this.$route.query.inform);
        if(this.$route.query.inform){
            this.informDialog = true;
        }
        // console.log(this.informDialog);
    },
    watch: {
        informDialog(newValue){
            if(newValue){ // เฉพาะเวลา เปิด dialog
                if(this.orderData?.payment_provider){
                    for(let bank of this.bankPaymentMethodBanks) {
                        let selected_payment = this.orderData?.payment_provider;
                        selected_payment = selected_payment.replace("billpayment", "bank_transfer");
                        selected_payment = selected_payment.replace("promptpay", "bank_transfer");
                        if(bank.key === selected_payment){
                            this.informPayment.bank = bank.key;
                            break;
                        }
                    }
                }

                if(this.selectedOrder && this.selectedOrder.length){
                    this.informPayment.order_ids = this.selectedOrder;
                    this.changePaymentMethodByOrders();
                }
                if(parseInt(this.orderData.lnwpay_flag)){
                    this.isSelectedOrdersIsLnwPay = true;
                }
            }
        },
    },
}
</script>
<style scoped></style>