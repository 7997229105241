<script>
import {mapState} from "vuex";

export default {
    name: 'MixinsContactMeta',
    data() {
        return {
            contactButton: null,
            showTooltipContactButton: false,
            contactMeta: {
                mobile: {
                    icon: 'mdi-phone',
                    url: 'tel:',
                    color: 'light-blue',
                },
                email: {
                    icon: 'mdi-at',
                    url: 'mailto:',
                    color: 'orange',
                },
                instagram: {
                    icon: 'mdi-instagram',
                    url: 'https://www.instagram.com/',
                    color: 'purple accent-3',
                },
                youtube: {
                    icon: 'mdi-youtube',
                    url: 'https://www.youtube.com/',
                    color: 'red',
                },
                twitter: {
                    icon: 'mdi-x',
                    url: 'https://www.x.com/',
                    color: '#000',
                },
                facebook: {
                    icon: 'mdi-facebook-messenger',
                    url: 'https://www.messenger.com/t/',
                    color: '#1778F2',
                },
                line: {
                    url: 'https://line.me/R/ti/p/~',
                    color: '#00B900',
                },
                tiktok: {
                    url: 'https://www.tiktok.com/@',
                    color: '#333',
                },
            },
        }
    },
    computed: {
        ...mapState({
            shopData: state => state.order.shopData?state.order.shopData:(state.myOrders.shopData),
            status: state => state.order.orderData?.order_status,
        }),
        contactList(){
            if(!this.shopData)
                return {};
            const payload = {};
            if(!this.shopData.is_close || this.shopData.show_primary_contact_when_close){
                payload.mobile = this.shopData.telephone;
                payload.email = this.shopData.email;
            }
            if(!this.shopData.is_close || this.shopData.show_contact_when_close){
                for(const item of this.shopData.social) {
                    payload[item.key] = item.id;
                }
            }
            return payload;
        },
    },
    watch: {
        contactButton(){
            if(this.contactButton){
                // console.log(this.contactButton);
                let that = this;
                setTimeout(function (){
                    // console.log(that.showTooltipContactButton);
                    that.showTooltipContactButton = true;
                }, 175);
            }else{
                this.showTooltipContactButton = false;
            }
        }
    }
}
</script>