<script>
import country_db from "../../../../resources/country-code.json";
import {mapActions, mapState} from "vuex";
import {setLocale} from "@/i18n";
import store from "@/store";
import {SET_IS_LOGIN_REQUIRED} from "@/store/modules/order/mutation-types";
import MixinsDisabledIconFocus from "@/mixins/MixinsDisabledIconFocus.vue";
import {isLocalhost} from '@/libraries/Utilities'

export default {
    name: "MixinsHelper",
    data: function(){
        return {
            isDesktop: false,
        }
    },
    mixins: [
        MixinsDisabledIconFocus
    ],
    computed: {
        ...mapState({
            existingOrderOwner: state => state.order.existingOrderOwner,
        }),
        isLocalhost() {
            return isLocalhost();
        },
        isLoginRequired: {
            // สำหรับว่าถ้ามีการผูก order ไปแล้ว (ไม่ใช่ว่า ร้านบังคับต้อง login ซึ่งจะใช้ key isRequiredLogin แทน
            get(){
                return this.$store.state.order.isLoginRequired;
            },
            set(value){
                this.$store.commit('order/' + SET_IS_LOGIN_REQUIRED, value);
            },
        },
    },
    methods: {
        ...mapActions({
            actionUpdateOrderLangAndCurrency: 'order/updateOrderLangAndCurrency',
        }),
        checkNoPermissionAndPopup() {
            if(this.existingOrderOwner){
                this.isLoginRequired = true;
                return true;
            }
            return false;
        },
        handleResize(){
            if(!this.isDesktop && window.outerWidth >= 1024){
                this.isDesktop = true;
            }else if(this.isDesktop && window.outerWidth < 1024){
                this.isDesktop = false;
            }
        },
        getQrUrl(href = window.location.href,width = '500'){
            return 'https://api.qrserver.com/v1/create-qr-code/?data='+ href + '&size=' + width + 'x' + width;
            // return 'https://chart.googleapis.com/chart?cht=qr&chl=' + href + '&chs=' + width + 'x' + width;
        },
        scrollTo(id, timeout = 50, addOffset = 0){
            let that = this;
            setTimeout(function(){
                let offsetHeight = 0;
                if(document.getElementById('infoFixedTop')){
                    offsetHeight = (document.getElementById('infoFixedTop').offsetHeight === 0 && that.isDesktop) ? 70 : document.getElementById('infoFixedTop').offsetHeight;
                }
                let offsetHeightBanner = 0;
                if(document.getElementById('bannerStatus')){
                    offsetHeightBanner = document.getElementById('bannerStatus').offsetHeight;
                }
                let offsetTotal = ((that.isDesktop) ? offsetHeightBanner : offsetHeight + offsetHeightBanner) + addOffset;
                that.$vuetify.goTo(id, {offset: offsetTotal});
            }, timeout);
        },
        error_message: function(err){
            if(err.key){
                let html = '';
                switch(err.key) {
                    case 'NOPRODUCT':
                    case 'HIDDEN':
                    case 'NOSELL':
                        return this.$t('ไม่มีสินค้าชนิดนี้ หรือสินค้าชนิดนี้ไม่ขาย');
                    case 'NOSTOCK':
                        html = this.$t('สินค้านี้เหลือไม่เพียงพอ');
                        return html.replace(/%d/, err.remain);
                    case 'BUY-WEB_CORRUPTION':
                        return this.$t('ขออภัยค่ะ เว็บนี้โดนระงับ ไม่สามารถสั่งซื้อสินค้าได้ค่ะ');
                    case 'INVALID_POINT':
                        return this.$t('กรุณากรอกตัวเลขเพื่อใช้คะแนนสะสม LnwPoints ค่ะ');
                    case 'USE>POINT':
                        return this.$t('ไม่สามารถใช้คะแนนสะสม LnwPoints เกินกว่าคะแนนที่คุณมีได้ค่ะ');
                    case 'POINT>NET':
                        return this.$t('ไม่สามารถใช้คะแนนสะสม LnwPoints มากกว่าราคารวมสินค้าได้ค่ะ');
                    case 'BUY-LNWPAY_ANTI-MONEY_LAUNDERING':
                        return this.$t('LnwPay ไม่อนุญาติให้สั่งซื้อสินค้าราคารวมเกิน 30,000 บาทต่อรายการสั่งซื้อได้ค่ะ');
                    case 'PERMISSION_DENIED':
                        return this.$t('คุณไม่สามารถเข้าถึงได้ กรุณาเข้าสู่ระบบ')
                    case 'INVALID_SHIPPINGTYPE':
                        return this.$t('วิธีการจัดส่งผิดพลาดค่ะ');
                    case 'ACCOUNT_LIMIT_REQUIRED_LOGIN':
                        return this.$t('จำกัดการซื้อต่อ 1 คน กรุณาเข้าสู่ระบบ');
                    case 'COUPON_INVALID_CONDITION':
                        if(this.isRealMall){
                            return this.$t('ไม่เข้าเกณฑ์เงื่อนไขของคูปอง / สินค้า Flash Sale จะไม่นับยอดรวมสำหรับการใช้คูปอง');
                        }
                        return this.$t('ไม่เข้าเกณฑ์เงื่อนไขของคูปอง');
                    case 'BELOW_QUANTITY':
                        html = this.$t('กรุณาสั่งซื้อขั้นต่ำ') + ' %d %s';
                        html = html.replace(/%d/, err.limit);
                        html = html.replace(/%s/, err.unit);
                        return html;
                    case 'OVER_QUANTITY':
                        html = this.$t('กรุณาสั่งซื้อไม่เกิน') + ' %d %s';
                        html = html.replace(/%d/, err.limit)
                        html = html.replace(/%s/, err.unit)
                        return html;
                    default:
                        if(this.$t(err.key) != ''){
                            return this.$t(err.key);
                        }
                        return err.key;
                }
            }
            return err;
        },
        toggleLang(){
            store.dispatch('order/setIsShowOverlay', true);
            const payload = {};

            if(this.$i18n.locale == 'th'){
                payload.lang_key = 'en';
                payload.currency_key = 'USD';
            }else{
                payload.lang_key = 'th';
                payload.currency_key = 'THB';
            }

            let that = this;
            // ถ้ามีเจ้าของและไม่ได้ login ก็ไม่ต้องไปอัพเดท แค่เปลี่ยนภาษาเท่านั้น
            if(this.existingOrderOwner){
                setLocale(payload.lang_key);
                store.dispatch('order/setIsShowOverlay', false);
                return;
            }
            this.actionUpdateOrderLangAndCurrency(payload)
                .then(async() => {
                    // if(!that.existingOrderOwner){
                    //     await that.fetchOrder();
                    // }else{
                    //     //หน้า login required ยังไม่มี orderdata.lang_key
                    //     setLocale(payload.lang_key);
                    // }
                    await that.fetchOrder();
                    setLocale(payload.lang_key);
                    await store.dispatch('order/setIsShowOverlay', false);
                })
                .catch(async(errors) => {
                    await store.dispatch('order/setIsShowOverlay', false);
                    //alert(errors); // @todo display nicely.
                    await store.dispatch('order/setIsAjaxResponse', false);
                    await store.dispatch('order/setIsAjaxResponse', true);
                    await store.dispatch('order/setIsAjaxResponseMessage', errors);
                })
        },
        getCountryNameByCode(code){
            let name = null;
            Object.keys(country_db)
                .forEach(function eachKey(key){
                    if(code == key){
                        name = country_db[key];
                    }
                });
            return name;
        },
        number_format(number, decimals, dec_point, thousands_sep) {
            var n = !isFinite(+number) ? 0 : +number,
                prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
                sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
                dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
                s = '',
                toFixedFix = function (n, prec) {
                    var k = Math.pow(10, prec);
                    return '' + Math.round(n * k) / k;
                };
            // Fix for IE parseFloat(0.55).toFixed(0) = 0;
            s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
            if (s[0].length > 3) {
                s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
            }
            if ((s[1] || '').length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1).join('0');
            }
            return s.join(dec);
        },
        mobile_format(number) {
            if (!number) {
                return '';
            }
            if (number.length == 10){
                return number.substr(0,3)+'-'+number.substr(3,3)+'-'+number.substr(6,4);
            }else if(number.length == 9){
                return number.substr(0,2)+'-'+number.substr(2,3)+'-'+number.substr(5,4);
            }
            return number;
        }
    },
    mounted: function(){
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    }
}
</script>